import { IMobxClubModelProps } from 'models/mobxClubModel';

const okorion: IMobxClubModelProps = {
  title: 'OK Orion',
  defaultLanguage: 'sv',
  map: {
    center: [15.82939, 56.20045],
    defaultZoomLevel: 16,
    layers: [
      {
        type: 'group',
        id: 'OrienteeringTileLayers',
        title: 'OK Orions kartor 2022',
        layers: [
          {
            type: 'base-tile',
            id: 'OrienteeringTileLayerOverview',
            title: 'Översikt',
            urlTemplate: 'https://okorion.com/maptiles/orienteering/wgs84/{z}/{x}/{y}.png',
            minZoomLevel: 7,
            maxZoomLevel: 14.9999999999999,
            fullExtent: {
              xmin: 15.39838,
              ymin: 56.0,
              xmax: 16.08225,
              ymax: 56.39629,
            },
          },
          {
            type: 'base-tile',
            id: 'OrienteeringTileLayer',
            title: 'Orienteringskarta',
            urlTemplate: 'https://okorion.com/maptiles/orienteering/wgs84/{z}/{x}/{y}.png',
            minZoomLevel: 15,
            maxZoomLevel: 17,
            fullExtent: {
              xmin: 15.39838,
              ymin: 56.0,
              xmax: 16.08225,
              ymax: 56.39629,
            },
            zoomExtent: {
              xmin: 15.847,
              ymin: 56.158,
              xmax: 15.853,
              ymax: 56.162,
            },
          },
        ],
      },
      {
        type: 'group',
        id: 'CoursesTileLayers',
        title: 'OK Orions banor värdetävlingar',
        visible: false,
        layers: [
          {
            type: 'group',
            id: 'CourseLayerElitserien2001',
            title: 'Elitserien 2001',
            layers: [
              {
                type: 'base-tile',
                id: 'CourseLayerElitserien2001-1-H21',
                title: 'Elitserien 2001:1 H21',
                urlTemplate: 'https://okorion.com/maptiles/courses/elitserien2001/1/h21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.42126,
                  ymin: 56.34907,
                  xmax: 15.4825,
                  ymax: 56.39411,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerElitserien2001-1-D21',
                title: 'Elitserien 2001:1 D21',
                urlTemplate: 'https://okorion.com/maptiles/courses/elitserien2001/1/d21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.43325,
                  ymin: 56.36599,
                  xmax: 15.4818,
                  ymax: 56.39413,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerElitserien2001-2-H21',
                title: 'Elitserien 2001:2 H21',
                urlTemplate: 'https://okorion.com/maptiles/courses/elitserien2001/2/h21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.42565,
                  ymin: 56.37695,
                  xmax: 15.47173,
                  ymax: 56.39566,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerElitserien2001-2-D21',
                title: 'Elitserien 2001:2 D21',
                urlTemplate: 'https://okorion.com/maptiles/courses/elitserien2001/2/d21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.42648,
                  ymin: 56.37692,
                  xmax: 15.47189,
                  ymax: 56.39565,
                },
                visible: false,
              },
            ],
          },
          {
            type: 'group',
            id: 'CourseLayerSM2016',
            title: 'SM 2016',
            layers: [
              {
                type: 'base-tile',
                id: 'CourseLayerSMMedel2016-D21',
                title: 'SM-Medel 2016 D21',
                urlTemplate: 'https://okorion.com/maptiles/courses/smmedel2016/d21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.77979,
                  ymin: 56.32512,
                  xmax: 15.82824,
                  ymax: 56.34839,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerSMMedel2016-H21',
                title: 'SM-Medel 2016 H21',
                urlTemplate: 'https://okorion.com/maptiles/courses/smmedel2016/h21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.77979,
                  ymin: 56.32512,
                  xmax: 15.82824,
                  ymax: 56.34839,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerSMStafett2016-D21',
                title: 'SM-Stafett 2016 D21',
                urlTemplate: 'https://okorion.com/maptiles/courses/smstafett2016/d21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.78268,
                  ymin: 56.10822,
                  xmax: 15.83786,
                  ymax: 56.13223,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerSMStafett2016-H21',
                title: 'SM-Stafett 2016 H21',
                urlTemplate: 'https://okorion.com/maptiles/courses/smstafett2016/h21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.78268,
                  ymin: 56.10822,
                  xmax: 15.83786,
                  ymax: 56.13223,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerSMStafett2016-D20',
                title: 'SM-Stafett 2016 D20',
                urlTemplate: 'https://okorion.com/maptiles/courses/smstafett2016/d20/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.78268,
                  ymin: 56.10822,
                  xmax: 15.83786,
                  ymax: 56.13223,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerSMStafett2016-H20',
                title: 'SM-Stafett 2016 H20',
                urlTemplate: 'https://okorion.com/maptiles/courses/smstafett2016/h20/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.78268,
                  ymin: 56.10822,
                  xmax: 15.83786,
                  ymax: 56.13223,
                },
                visible: false,
              },
            ],
          },
          {
            type: 'group',
            id: 'CourseLayerSwedishLeague2017',
            title: 'Swedish League 2017',
            layers: [
              {
                type: 'base-tile',
                id: 'CourseLayerSwedishLeague2017-1-D21',
                title: 'Swedish League 2017:1 D21',
                urlTemplate: 'https://okorion.com/maptiles/courses/swedishleague2017/1/d21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.81489,
                  ymin: 56.13409,
                  xmax: 15.87094,
                  ymax: 56.16357,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerSwedishLeague2017-1-H21',
                title: 'Swedish League 2017:1 H21',
                urlTemplate: 'https://okorion.com/maptiles/courses/swedishleague2017/1/h21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.81489,
                  ymin: 56.13409,
                  xmax: 15.87094,
                  ymax: 56.16357,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerSwedishLeague2017-2-D21',
                title: 'Swedish League 2017:2 D21',
                urlTemplate: 'https://okorion.com/maptiles/courses/swedishleague2017/2/d21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.7867,
                  ymin: 56.11002,
                  xmax: 15.82642,
                  ymax: 56.13948,
                },
                visible: false,
              },
              {
                type: 'base-tile',
                id: 'CourseLayerSwedishLeague2017-2-H21',
                title: 'Swedish League 2017:2 H21',
                urlTemplate: 'https://okorion.com/maptiles/courses/swedishleague2017/2/h21/wgs84/{z}/{x}/{y}.png',
                minZoomLevel: 12,
                maxZoomLevel: 17,
                fullExtent: {
                  xmin: 15.7867,
                  ymin: 56.11002,
                  xmax: 15.82642,
                  ymax: 56.13948,
                },
                visible: false,
              },
            ],
          },
        ],
      },
    ],
  },
  loginUrl: 'https://okorion.com/log_in.php',
  logoutUrl: 'https://okorion.com/log_out.php',
  attachmentUrl: 'https://okorion.com/showfile.php?iFileID=',
  titleLogo: {
    url: 'https://okorion.com/images/okorion_text.png',
    width: 375,
    height: 45,
  },
  logo: {
    url: 'https://okorion.com/images/okorion_logo.png',
    width: 379,
    height: 423,
  },
  theme: {
    palette: {
      primary: {
        main: '#5882E4',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#000000',
      },
      error: {
        main: '#aa3333',
        contrastText: '#000000',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontSize: 12,
    },
  },
  modules: [
    {
      name: 'News',
      addUrl: 'https://okorion.com/news/save.php',
      deleteUrl: 'https://okorion.com/news/delete.php',
      updateUrl: 'https://okorion.com/news/save.php',
      queryUrl: 'https://okorion.com/news/jsonNewsQuery.php',
    },
    {
      name: 'Calendar',
      queryUrl: 'https://okorion.com/calendar/jsonCalendarQuery.php',
      addUrl: 'https://okorion.com/calendar/saveCalendar.php',
      deleteUrl: 'https://okorion.com/calendar/delete.php',
      updateUrl: 'https://okorion.com/calendar/saveCalendar.php',
    },
    { name: 'Stars' },
    { name: 'ScoringBoard' },
    { name: 'Eventor' },
    {
      name: 'Results',
      addUrl: 'https://okorion.com/result/save.php',
      deleteUrl: 'https://okorion.com/result/delete.php',
      updateUrl: 'https://okorion.com/result/save.php',
      queryUrl: 'https://okorion.com/result/jsonResultQuery.php',
      league: {
        rankingLeagueAgeLimit: 15,
        rankingRelayLeagueAgeLimit: 15,
        points1000LeagueAgeLimit: 0,
        pointsLeagueAgeLimit: 15,
      },
    },
    {
      name: 'Users',
      addUrl: 'https://okorion.com/users/save.php',
      deleteUrl: 'https://okorion.com/users/delete.php',
      updateUrl: 'https://okorion.com/users/save.php',
      queryUrl: 'https://okorion.com/users/jsonUserQuery.php',
    },
    { name: 'Photo' },
    {
      name: 'HTMLEditor',
      addUrl: 'https://okorion.com/htmlEditor/save.php',
      deleteUrl: 'https://okorion.com/htmlEditor/delete.php',
      updateUrl: 'https://okorion.com/htmlEditor/save.php',
      queryUrl: 'https://okorion.com/htmlEditor/jsonHtmlEditorQuery.php',
    },
    {
      name: 'Files',
      addUrl: 'https://okorion.com/files/save.php',
      deleteUrl: 'https://okorion.com/files/delete.php',
      updateUrl: 'https://okorion.com/files/save.php',
      queryUrl: 'https://okorion.com/files/jsonFilesQuery.php',
    },
  ],
  links: [{ name: 'SOFT', url: 'https://www.svenskorientering.se' }],
  sports: ['Orientering'],
  eventor: {
    organisationId: 288,
    districtOrganisationId: 7,
  },
  corsProxy: 'https://okorion.com/proxy.php?csurl=',
  eventorCorsProxy: 'https://okorion.com/eventorProxyWithCache.php',
  sponsors: [
    {
      name: 'Affärsverken',
      logo: {
        url: 'https://okorion.com/images/sponsors/affarsverken.jpg',
        width: 2358,
        height: 766,
      },
      url: 'https://www.affarsverken.se/',
      active: false,
    },
    {
      name: 'Bergkvarabuss',
      logo: {
        url: 'https://okorion.com/images/sponsors/bergkvarabuss.svg',
        width: 280,
        height: 48,
      },
      url: 'https://bergkvarabuss.se/',
      active: false,
    },
    {
      name: 'BLT',
      logo: {
        url: 'https://okorion.com/images/sponsors/blt.svg',
        width: 300,
        height: 61,
      },
      url: 'https://blt.se/',
      active: false,
    },
    {
      name: 'Börjessons',
      logo: {
        url: 'https://okorion.com/images/sponsors/borjessons.png',
        width: 620,
        height: 183,
      },
      url: 'https://www.borjessonsbil.se/',
      active: true,
    },
    {
      name: 'EY',
      logo: {
        url: 'https://okorion.com/images/sponsors/ey.svg',
        width: 97,
        height: 103,
      },
      url: 'https://www.ey.com/sv_se',
      active: true,
    },
    {
      name: 'eye do',
      logo: {
        url: 'https://okorion.com/images/sponsors/eyedo.png',
        width: 375,
        height: 239,
      },
      url: 'http://www.eye-do.se/',
      active: true,
    },
    {
      name: 'ICA Supermarket Jämjö',
      logo: {
        url: 'https://okorion.com/images/sponsors/ica-jamjo.png',
        width: 500,
        height: 313,
      },
      url: 'https://www.ica.se/butiker/supermarket/karlskrona/ica-supermarket-jamjo-2450/start/',
      active: true,
    },
    {
      name: 'iLOG',
      logo: {
        url: 'https://okorion.com/images/sponsors/ilog.png',
        width: 600,
        height: 104,
      },
      url: 'https://www.wip.se/',
      active: true,
    },
    {
      name: 'Jämjö El',
      logo: {
        url: 'https://okorion.com/images/sponsors/jamjoel.jpg',
        width: 2196,
        height: 424,
      },
      url: 'https://www.jamjoel.se/',
      active: false,
    },
    {
      name: 'Karlskronahem',
      logo: {
        url: 'https://okorion.com/images/sponsors/karlskronahem.svg',
        width: 220,
        height: 63,
      },
      url: 'https://www.karlskronahem.se/',
      active: false,
    },
    {
      name: 'Karlskrona kommun',
      logo: {
        url: 'https://okorion.com/images/sponsors/bla-karlskrona-kommun.png',
        width: 2537,
        height: 643,
      },
      url: 'https://www.karlskrona.se/',
      active: true,
    },
    {
      name: 'Destination Kosta',
      logo: {
        url: 'https://okorion.com/images/sponsors/destinationkosta.png',
        width: 986,
        height: 591,
      },
      url: 'https://destinationkosta.se/',
      active: true,
    },
    {
      name: 'Länsförsäkringar Blekinge',
      logo: {
        url: 'https://okorion.com/images/sponsors/lansforsakringar.png',
        width: 501,
        height: 100,
      },
      url: 'https://www.lansforsakringar.se/blekinge/',
      active: true,
    },
    {
      name: 'Martins grus',
      logo: {
        url: 'https://okorion.com/images/sponsors/martinsgrus.png',
        width: 598,
        height: 300,
      },
      url: 'https://martinsgrus.se/',
      active: true,
    },
    {
      name: 'Roxtec',
      logo: {
        url: 'https://okorion.com/images/sponsors/roxtec.svg',
        width: 165,
        height: 41,
      },
      url: 'https://www.roxtec.com/sv/',
      active: false,
    },
    {
      name: 'S-GROUP Solutions',
      logo: {
        url: 'https://okorion.com/images/sponsors/sgroup-solutions.svg',
        width: 425,
        height: 40,
      },
      url: 'https://www.sgroup-solutions.se/',
      active: true,
    },
    {
      name: 'Stensborgs VVS',
      logo: {
        url: 'https://okorion.com/images/sponsors/stensborg.png',
        width: 707,
        height: 158,
      },
      active: true,
    },
    {
      name: 'Swedbank',
      logo: {
        url: 'https://okorion.com/images/sponsors/swedbank.png',
        width: 300,
        height: 64,
      },
      url: 'https://www.swedbank.se/',
      active: false,
    },
    {
      name: 'Sydöstran',
      logo: {
        url: 'https://okorion.com/images/sponsors/sydostran.svg',
        width: 300,
        height: 61,
      },
      url: 'https://sydostran.se/',
      active: false,
    },
    {
      name: 'Trimtex',
      logo: {
        url: 'https://okorion.com/images/sponsors/trimtex.png',
        width: 740,
        height: 137,
      },
      url: 'https://trimtex.se/',
      active: true,
    },
    {
      name: 'WSP',
      logo: {
        url: 'https://okorion.com/images/sponsors/wsp.png',
        width: 1781,
        height: 848,
      },
      url: 'https://www.wsp.com/sv-SE',
      active: true,
    },
    {
      name: 'XL Bygg Jämjö',
      logo: {
        url: 'https://okorion.com/images/sponsors/xlbygg_jamjo.png',
        width: 315,
        height: 95,
      },
      url: 'https://www.xlbygg.se/jamjo/',
      active: false,
    },
  ],
  facebookUrl: 'https://www.facebook.com/okorion',
};

export default okorion;
